<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Predict the major product of the following reaction:</p>
      <v-img :src="imageHeaderName" class="mb-3" style="max-width: 500px" />

      <v-radio-group v-model="inputs.input1" class="mb-3 ml-3" :disabled="!allowEditing">
        <v-radio class="my-2" value="a">
          <template #label>
            <v-img :src="image1Name" max-width="300px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="b">
          <template #label>
            <v-img :src="image2Name" max-width="300px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="c">
          <template #label>
            <v-img :src="image3Name" max-width="300px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="d">
          <template #label>
            <v-img :src="image4Name" max-width="300px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="e">
          <template #label>
            <v-img :src="image5Name" max-width="300px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="f">
          <template #label>
            <v-img :src="image6Name" max-width="300px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemLC241PS10Q7',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    imageHeaderName() {
      return '/img/assignments/LC241/ps10-q7-header.png';
    },
    image1Name() {
      return '/img/assignments/LC241/ps10-q7-option1.png';
    },
    image2Name() {
      return '/img/assignments/LC241/ps10-q7-option2.png';
    },
    image3Name() {
      return '/img/assignments/LC241/ps10-q7-option3.png';
    },
    image4Name() {
      return '/img/assignments/LC241/ps10-q7-option4.png';
    },
    image5Name() {
      return '/img/assignments/LC241/ps10-q7-option5.png';
    },
    image6Name() {
      return '/img/assignments/LC241/ps10-q7-option6.png';
    },
  },
};
</script>
